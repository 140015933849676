.nav-link {
  background-color: #14919b;
  color: white;
  margin: 0 10px;
  transition: transform 0.3s;
  font-size: 24px;
  border-radius: 12px;
  font-weight: bold;
}

.nav-link:hover {
  transform: scale(1.1);
}

.nav-link.active {
  text-decoration: underline;
}

.navbar {
  padding: 1rem 1rem;
}
