.upload-container {
  background-color: #f0f4f8;
}

.redact-checkbox .form-check-input {
  width: 40px;
  height: 40px;
  border-radius: 0.5em;
  border: 2px solid #14919b;
}

.redact-checkbox .form-check-input:checked {
  background-color: #14919b;
  border-color: #14919b;
}

.redact-checkbox .form-check-label {
  margin-left: 10px;
}
