.footer {
  background-color: #213a57;
  padding: 20px 0;
}

.footer img {
  max-width: 100%;
  height: 150px;
}

.social-icons a {
  font-size: 48px;
  color: #ffffff;
}
