.headerContent {
  .header {
    height: 25%;
    background-color: #213a57;
    .row {
      color: #0ad1c8 !important;
      text-align: center;
      .headerTitle {
        margin: 0;
        font-size: 64px;
        font-weight: bolder;
      }
      .headerSlogan {
        font-style: italic;
        color: white;
        font-size: x-large;
      }
    }
  }
}
